import { Map as map } from 'immutable';
import {
	CUSTOMER_VALIDATION,
	ALL_PRODUCTS,
	SELECT_PLAN,
	CHECKED_TERMS,
	CHANGE_VALUE_INPUT_AFFILIATION,
	CHANGE_STATE_AFFILIATION,
	CALCULATE_TOTAL_PAYMENT,
	CLEAN_AFFILIATION,
	CLEAN_AFFILIATION_ADMIN,
	CLEAN_FORM_ERRORS_BENEFICIARIES,
	SAVE_TOKEN,
	USE_TITULAR_DATA,
	SAVE_USER_TYPE,
	SAVE_TITULAR_EMAIL,
	SHOW_DISCOUNT_PERCENT,
	SET_IS_CLIENT,
	SAVE_ALLY_NAME,
	UPDATE_TITULAR_DATA,
	SAVE_USERID,
	SAVE_MOBILE_APPS_LINKS,
} from '../actionTypes';
import BasePayment from '../../src/components/utils/BasePayment';
import BaseTitular from '../../src/components/utils/BaseTitular';

const isServer = typeof window === 'undefined';
let initialState = map({});
if (!isServer) {
	initialState = map({
		// dataFrontend
		dni: '',
		email: '',
		formErrors: [],
		// dataBackend
		titular: { ...BaseTitular },
		beneficiaries: [],
		customers: [],
		products: [],
		is_saved: false,
		is_titular: true,
		is_lead: false,
		is_customer: false,
		init_affiliation: false,
		existPassword: true,

		// campaigns
		campaign_id: '',
		campaign_name: '',
		product_type: '',
		product_price: 0,
		company_id: '',
		company_name: '',
		plans: '',
		terms: false,

		// affiliation
		payment: BasePayment,
		totalPayment: 0,
		total_currency: 0,
		coupon: localStorage.getItem('coupon'),
		percent_off: parseInt(localStorage.getItem('percent_off')),
		free_trial: localStorage.getItem('free_trial'),
		is_back_coupon: localStorage.getItem('is_back_coupon'),
		quotation: [],
		payment_info: [],

		// Session
		token: localStorage.getItem('token'),
		user_type: parseInt(localStorage.getItem('user_type')),
		email: localStorage.getItem('email'),
		is_client: localStorage.getItem('is_client') || false,

		//mobile apps links
		android_app_link: localStorage.getItem('android_app_link'),
		ios_app_link: localStorage.getItem('ios_app_link'),

		// Titular data on payment form
		useTitularData: false,
		products: []
	});
}

const affiliation = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_STATE_AFFILIATION:
			const { name, value } = action.payload.values.data;
			if (name === 'titular') {
				let titular = value;
				titular.nationality = !titular.nationality
					? titular?.document_type
					: titular?.nationality;
			}
			return state.set(name, value);

		case CHANGE_VALUE_INPUT_AFFILIATION:
			const { nameInput, valueInput } = action.payload.values.data;
			return state.set(nameInput, valueInput);

		case CUSTOMER_VALIDATION:
			let {
				titular,
				beneficiaries,
				products,
				is_saved,
				is_lead,
				is_customer,
				init_affiliation,
				coupon
			} = action.payload.values.data;

			let payment = state.get('payment');
			payment = {
				...payment,
				coupon
			};

			return (
				state
					.set('beneficiaries', beneficiaries)
					.set('products', products)
					.set('is_saved', is_saved)
					.set('is_lead', is_lead)
					.set('is_customer', is_customer)
					.set('init_affiliation', init_affiliation)
					.set('coupon', coupon)
					.set('titular', titular)
					// payment
					.set('payment', payment)
			);

		case ALL_PRODUCTS:
			return state.set('products', action.payload.values);

		case SELECT_PLAN:
			let { product_type, index } = action.payload.values.data;
			let product_price = product_type.price_base;
			beneficiaries = state.get('beneficiaries');
			beneficiaries[index].product_type = product_type;
			beneficiaries[index].product_price = product_price;
			beneficiaries[index].total = product_price;

			return state
				.set('beneficiaries', beneficiaries)
				.set('product_type', product_type)
				.set('product_price', product_price)
				.set('total', total);

		case CHECKED_TERMS:
			let { terms } = action.payload.values.data;
			titular = state.get('titular');
			titular = {
				...titular,
				agree_policy_privacy: terms
			};
			return state.set('titular', titular).set('terms', terms);

		case CALCULATE_TOTAL_PAYMENT:
			payment = state.get('payment');
			payment.totalPayment = action.payload.values.total;
			payment.total_currency = action.payload.values.total_currency;
			payment.currency = action.payload.values.isoCode;
			return state
				.set('totalPayment', action.payload.values.total)
				.set('total_currency', action.payload.values.total_currency)
				.set('payment', payment);

		case SAVE_TOKEN:
			localStorage.setItem('token', action.payload.values.token);
			return state.set('token', action.payload.values.token);

		case SAVE_USER_TYPE:
			localStorage.setItem('user_type', action.payload.values.user_type);
			return state.set('user_type', action.payload.values.user_type);

		case SAVE_TITULAR_EMAIL:
			localStorage.setItem('email', action.payload.values.email);
			titular = state.get('titular');
			titular.email = action.payload.values.email;
			return state
				.set('titular', titular)
				.set('email', action.payload.values.email);

		case CLEAN_AFFILIATION:
			localStorage.setItem('percent_off', 0);
			localStorage.setItem('coupon', '');
			localStorage.getItem('free_trial');
			localStorage.getItem('is_back_coupon');

			return (
				state
					.set('dni', '')
					// .set('email', '')
					.set('formErrors', [])
					.set('beneficiaries', [])
					// .set('products', [])
					.set('is_saved', false)
					.set('is_lead', false)
					.set('is_customer', false)
					.set('init_affiliation', false)
					.set('campaign_id', '')
					.set('campaign_name', '')
					.set('company_id', '')
					.set('company_name', '')
					.set('product_type', '')
					.set('product_price', 0)
					.set('terms', false)
					.set('payment', BasePayment)
					// .set('titular', BaseTitular)
					.set('totalPayment', 0)
					.set('total_currency', 0)
					.set('percent_off', 0)
					.set('coupon', '')
					.set('free_trial', false)
					.set('is_back_coupon', false)
					.set('titular', { ...BaseTitular })
					.set('payment', { ...BasePayment })
			);

		case CLEAN_AFFILIATION_ADMIN:
			localStorage.setItem('percent_off', 0);
			localStorage.setItem('coupon', '');
			localStorage.setItem('free_trial', false);
			localStorage.setItem('is_back_coupon', false);
			return (
				state
					.set('dni', '')
					// .set('email', '')
					.set('formErrors', [])
					.set('beneficiaries', [])
					.set('titular', { ...BaseTitular })
					// .set('products', [])
					.set('is_saved', false)
					.set('is_lead', false)
					.set('is_customer', false)
					.set('init_affiliation', false)
					.set('campaign_id', '')
					.set('campaign_name', '')
					.set('company_id', '')
					.set('company_name', '')
					.set('product_type', '')
					.set('product_price', 0)
					.set('terms', false)
					.set('payment', BasePayment)
					// .set('titular', BaseTitular)
					.set('totalPayment', 0)
					.set('total_currency', 0)
					.set('percent_off', 0)
					.set('coupon', '')
					.set('free_trial', false)
					.set('is_back_coupon', false)
			);

		case USE_TITULAR_DATA:
			payment = state.get('payment');
			titular = state.get('titular');

			if (action.payload.values === true) {
				payment.use_titular_data = true;
				payment.first_name = titular.first_name;
				payment.last_name = titular.last_name;
				payment.dni = titular.dni;
				payment.email = titular.email;
				payment.phone_one = titular.phone_one;
				payment.country = titular.country;
				payment.city = titular.city === 'default' ? '' : titular.city;
				payment.address = titular.address;
				delete payment.formErrors.country;
				delete payment.formErrors.city;
			} else {
				payment.use_titular_data = false;
				payment.first_name = '';
				payment.last_name = '';
				payment.dni = '';
				payment.email = '';
				payment.phone_one = '';
				payment.country = 'default';
				payment.city = '';
				payment.address = '';
			}

			return state.set('payment', payment);
		// .set('useTitularData', action.payload.values);

		case CLEAN_FORM_ERRORS_BENEFICIARIES:
			beneficiaries = state.get('beneficiaries');
			beneficiaries.map((beneficiary) => (beneficiary.formErrors = []));
			return state.set('beneficiaries', beneficiaries);

		case SHOW_DISCOUNT_PERCENT:
			payment = state.get('payment');
			payment.coupon = action.payload.values.coupon;

			localStorage.setItem('percent_off', action.payload.values.percent_off);
			localStorage.setItem('coupon', action.payload.values.coupon);
			localStorage.setItem('free_trial', action.payload.values.free_trial);
			localStorage.setItem(
				'is_back_coupon',
				action.payload.values.is_back_coupon
			);
			return state
				.set('percent_off', action.payload.values.percent_off)
				.set('coupon', action.payload.values.coupon)
				.set('free_trial', action.payload.values.free_trial)
				.set('is_back_coupon', action.payload.values.is_back_coupon)
				.set('payment', payment);

		case SET_IS_CLIENT:
			localStorage.setItem('is_client', action.payload.values.is_client);
			return state.set('is_client', action.payload.values.is_client);

		case SAVE_ALLY_NAME:
			return state.set('ally_name', action.payload.values.ally_name);

		case UPDATE_TITULAR_DATA:
			return state.set('titular', action.payload.values);

		case SAVE_USERID:
			localStorage.setItem('userID', action.payload.values.userID);
			return state.set('userID', action.payload.values.userID);

		case SAVE_MOBILE_APPS_LINKS:
			localStorage.setItem(
				'android_app_link',
				action.payload?.android_app_link
			);
			localStorage.setItem('ios_app_link', action.payload?.ios_app_link);
		return state
			.set('ios_app_link', action.payload?.ios_app_link)
			.set('android_app_link', action.payload?.android_app_link);

		default:
			return state;
	}
};

export default affiliation;
