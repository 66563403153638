import axios from 'axios';
import { Map as map } from 'immutable';
import {
	SET_CURRENT_COUNTRY,
	SET_COUNTRIES,
	SET_COUNTRY,
	SET_REGION,
	SET_REGIONS
} from '../actionTypes';
import { Region } from '../../src/models/region';
import { Country } from '../../src/models/country';

const initialState = map({
	listCountries: [],
	country: new Country({}),
	listRegions: [],
	region: new Region({}),
	page: 0,
	rowsPerPage: 5,
	currentCountry: 've'
});

const country = (state = initialState, action) => {
	switch (action.type) {
		case SET_CURRENT_COUNTRY:
			axios.defaults.headers['Asistensi-country'] = action.payload;
			return state.set('currentCountry', action.payload);
		case SET_COUNTRIES:
			return state.set('listCountries', action.payload);
		case SET_COUNTRY:
			return state.set('country', new Country(action.payload));
		case SET_REGIONS:
			return state.set('listRegions', action.payload);
		case SET_REGION:
			return state.set('region', new Region(action.payload));
		default:
			return state;
	}
};

export default country;
