import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import Head from 'next/head';
import App from 'next/app';
import withReduxStore from '../lib/with-redux-store';
import { Provider } from 'react-redux';
import { SplashScreen, LinearProgressBar } from 'components/widgets';
import { _calculateAge } from 'components/utils/CalculateAge';
import Common from '../src/services/common';
import {
	SET_ORIGIN,
	SAVE_TOKEN,
	SAVE_USER_TYPE,
	SET_IS_CLIENT,
	SIGNIN_ALLY,
	CHANGE_STATE_AFFILIATION,
	SAVE_TITULAR_EMAIL,
	SHOW_DISCOUNT_PERCENT
} from '../store/actionTypes';
import BaseTitular from 'components/utils/BaseTitular';
import { Beneficiary } from 'models/beneficiary';
import { removeRecaptchaV3 } from 'components/utils/recaptchaV3';

import 'theme/tailwind.scss';

import 'react-telephone-input/css/default.css';
import 'react-multi-carousel/lib/styles.css';
import { connectToSocketServer } from '../server/utils/socket-client';

if (typeof global.navigator === 'undefined') global.navigator = {};

const tagManagerID = process.env.REACT_APP_TAG_MANAGER_ARGS;

function MyApp({ Component, pageProps, reduxStore }) {
	const router = useRouter();

	const [loading, setLoading] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);

	const firstRequest = () => {
		return new Promise((resolve) => setTimeout(() => resolve(), 1));
	};

	const handleStateAffiliation = async (name, value) => {
		await reduxStore.dispatch({
			type: CHANGE_STATE_AFFILIATION,
			payload: {
				values: {
					data: { name, value }
				}
			}
		});
	};

	const calculations = async (ben) => {
		for (const b of ben) {
			b.formErrors = [];
			b.age = _calculateAge(b.birth_date);

			Common.get_plans_by_age({ age: b.age }).then((r) => {
				if (r.data.products) b.products = r.data.products;
				if ((!b.questions || b.questions?.length === 0) && b.product_type) {
					b.questions = b.product_type.questions;
				}
			});
		}
		return ben;
	};

	useEffect(() => {
		// Initialize Tag Manager
		const tagManagerArgs = { gtmId: tagManagerID };
		// ReactGA.initialize(trackingId);
		TagManager.initialize(tagManagerArgs);

		// Remove the server-side injected CSS.
		const jssStyles = document.querySelector('#jss-server-side');
		if (jssStyles) {
			jssStyles.parentElement.removeChild(jssStyles);
		}

		// call the function fake for show first spinner when is loading page
		firstRequest().then(() => {
			const el = document.querySelector('.loader-container');
			if (el) {
				el.remove();
				setFirstLoad(false);
			}
		});
	}, []);

	useEffect(() => {
		const handleStart = () => setLoading(true);
		// handleComplete event was not firing
		const handleComplete = () => setLoading(false);

		router.events.on('routeChangeStart', handleStart);
		router.events.on('routeChangeComplete', handleComplete);
		router.events.on('routeChangeError', handleComplete);

		return () => {
			router.events.off('routeChangeStart', handleStart);
			router.events.off('routeChangeComplete', handleComplete);
			router.events.off('routeChangeError', handleComplete);
		};
	}, [loading]);

	useEffect(() => {
		const { dispatch } = reduxStore;
		if (
			router?.query?.hash &&
			!router?.asPath.includes('/resumen-afiliacion')
		) {
			setLoading(true);
			const hash = router?.query?.hash.replace(/ /g, '+');
			Common.validate_session_hash({ hash })
				.then(async (res) => {
					const {
						beneficiaries,
						customers,
						token,
						user_type,
						email,
						percent_off,
						coupon,
						free_trial,
						doc_dni,
						origin,
						is_client
					} = res.data;
					await dispatch({ type: SET_ORIGIN, payload: { values: origin } });
					await dispatch({ type: SAVE_TOKEN, payload: { values: { token } } });
					await dispatch({
						type: SAVE_USER_TYPE,
						payload: { values: { user_type } }
					});
					await dispatch({
						type: SET_IS_CLIENT,
						payload: { values: { is_client } }
					});
					const result = beneficiaries.find(
						(benefit) => benefit.kinship === 'TITULAR'
					);
					let titular = { ...BaseTitular };

					if (result) {
						titular = { ...titular, ...result };
						titular.include_titular = true;
						if (doc_dni) {
							titular.document_dni = 'Archivo cargado';
							titular.doc_dni = doc_dni;
						}
						titular.age = _calculateAge(titular.birth_date);
						await Common.get_plans_by_age({ age: titular.age }).then((r) => {
							if (r.data.products) {
								titular.products = r.data.products;
							}
						});
						if (
							(!titular.questions || titular.questions?.length === 0) &&
							titular.product_type
						) {
							titular.questions = titular.product_type.questions;
						}
					} else {
						await Common.get_titular_data().then((r) => {
							titular = { ...titular, ...r.data.titular };
							if (titular.doc_dni) {
								titular.document_dni = 'Archivo cargado';
							} else if (doc_dni) {
								titular.document_dni = 'Archivo cargado';
							}
							if (!titular.birth_date) titular.birth_date = null;
						});
					}
					if (user_type === 100 || user_type === 110 || user_type === 111) {
						await dispatch({
							type: SIGNIN_ALLY,
							payload: {
								values: {
									...res.data,
									first_name: titular.first_name,
									last_name: titular.last_name,
									share_link: titular.share_link,
									share_link_discount: titular.share_link_discount
								}
							}
						});
					}
					titular.nationality = !titular.nationality
						? titular.document_type
						: titular.nationality;
					await handleStateAffiliation('titular', { ...titular });
					const beneficiariesUpdated = await calculations(beneficiaries);
					const allBeneficiaries = beneficiariesUpdated.map(
						(b) => new Beneficiary(b)
					);
					await handleStateAffiliation('beneficiaries', allBeneficiaries);
					await handleStateAffiliation('customers', customers);
					await dispatch({
						type: SAVE_TITULAR_EMAIL,
						payload: { values: { email } }
					});
					if (percent_off) {
						await dispatch({
							type: SHOW_DISCOUNT_PERCENT,
							payload: {
								values: {
									percent_off: percent_off,
									coupon: coupon,
									free_trial: free_trial,
									is_back_coupon: true
								}
							}
						});
					}
					router.replace('/');
					setLoading(false);
				})
				.catch((err) => setLoading(false));
		}
	}, [router.query]);

	useEffect(() => {
		// hide recaptcha
		if (
			!router.pathname.includes('signIn') ||
			!router.pathname.includes('signUp')
		) {
			removeRecaptchaV3();
		}
	}, [router]);

	useEffect(() => {
		connectToSocketServer();
	}, []);

	return (
		<div>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<meta name="theme-color" content="#f7553b" />
				<meta name="apple-mobile-web-app-capable" content="yes" />

				<meta
					httpEquiv="Cache-Control"
					content="no-cache, no-store, must-revalidate"
				/>
				<meta httpEquiv="Pragma" content="no-cache" />
				<meta httpEquiv="Expires" content="0" />
				<meta
					name="description"
					content="Cuida la salud de tu familia con asistensi. Seguro de emergencias médicas con atención inmediata, ambulancias y especialistas. ¡Contrata desde cualquier lugar!"
				/>
				<script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
				<title>
					asistensi República Dominicana | Seguro de emergencias médicas
				</title>
			</Head>
			<Provider store={reduxStore}>
				{firstLoad && <SplashScreen />}
				{loading && <LinearProgressBar />}
				<div className={loading ? 'hidden' : ''}>
					<Component {...pageProps} />
				</div>
			</Provider>
		</div>
	);
}

MyApp.getInitialProps = async (appContext) => {
	const appProps = await App.getInitialProps(appContext);
	return { ...appProps };
};

export default withReduxStore(MyApp);
