import { Map as map } from 'immutable';
import {
	SAVETITULARDATA,
	SAVEBENEFICIARIESDATA,
	SAVE_QUOTATION_DATA,
	SAVE_QUOTATION_CLIENT,
	CLEAN_QUOTATION
} from '../actionTypes';

const initialState = map({
	beneficiaries: [],
	quotation: {
		country: 'República Dominicana',
		city: 'default',
		amount: 'default',
		percent_off: 'default',
		parients: []
	},
	client: {}
});

function customer(state = initialState, action) {
	switch (action.type) {
		case SAVETITULARDATA:
			return state.set('titular', action.payload.values.titular);

		case SAVEBENEFICIARIESDATA:
			return state.set('beneficiaries', action.payload.values.beneficiaries);

		case SAVE_QUOTATION_DATA:
			return state.set('quotation', action.payload.values.quotation);

		case SAVE_QUOTATION_CLIENT:
			return state.set('client', action.payload.values.client);

		case CLEAN_QUOTATION:
			return state.set('client', {}).set('quotation', {
				country: 'República Dominicana',
				city: 'default',
				amount: 'default',
				percent_off: 'default',
				parients: []
			});

		default:
			return state;
	}
}

export default customer;
