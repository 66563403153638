import { Manager } from "socket.io-client";

const WEBSOCKET_SERVER = process.env.REACT_APP_WEBSOCKET_SERVER;

export const connectToSocketServer = () => {
    const manager = new Manager(WEBSOCKET_SERVER)
    const socket = manager.socket('/')

    socket.on('connect', () => {
        console.log('connected')
    })

    socket.on('disconnect', () => {
        console.log('disconnected')
    })

    socket.on('reload-do', () => {
        reloadAndClearCaches()
    })

    socket.on('reload-all', () => {
        reloadAndClearCaches()
    })
}

const reloadAndClearCaches = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    localStorage.clear()
    window.location.reload()
}