import { Map as map } from 'immutable';
import {
	GET_ALL_CANCELLATION,
	PROFILE_STEP,
	SELECT_BENEFICIARY,
	SHOW_EDIT_DIALOG,
	UPDATE_PAYMENT_INFO,
	CLEAN_CANCELLATION,
	SET_CANCELLATION,
	SAVE_LINK,
	RENEW_PAYMENT
} from '../actionTypes';

const isServer = typeof window === 'undefined';
let initialState = map({});
if (!isServer) {
	initialState = map({
		step: '',
		indexBeneficiary: '',
		beneficiaryToEdit: '',
		showEditDialog: false,
		paymentInfoUpdated: {},
		listCancellations: [],
		cancellation: {},
		share_link: localStorage.getItem('share_link'),
		share_link_discount: localStorage.getItem('share_link_discount'),
		renewPayment: []
	});
}

function customer(state = initialState, action) {
	switch (action.type) {
		case PROFILE_STEP:
			return state.set('step', action.payload.values.step);

		case SELECT_BENEFICIARY:
			const index = parseInt(action.payload.values.index);
			return state
				.set('indexBeneficiary', index)
				.set('beneficiaryToEdit', action.payload.values.beneficiary);

		case SHOW_EDIT_DIALOG:
			return state.set('showEditDialog', action.payload.values);

		case UPDATE_PAYMENT_INFO:
			return state.set('paymentInfoUpdated', action.payload.values);

		case GET_ALL_CANCELLATION:
			return state.set('listCancellations', action.payload);

		case SET_CANCELLATION:
			return state.set('cancellation', action.payload);

		case CLEAN_CANCELLATION:
			return state.set('cancellation', {});

		case SAVE_LINK:
			localStorage.setItem('share_link', action.payload.values.share_link);
			localStorage.setItem(
				'share_link_discount',
				action.payload.values.share_link_discount
			);
			return state
				.set('share_link', action.payload.values.share_link)
				.set('share_link_discount', action.payload.values.share_link_discount);

		case RENEW_PAYMENT:
			return state.set('renewPayment', action.payload);

		default:
			return state;
	}
}

export default customer;
